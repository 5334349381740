const palette = {
  white: "#FFFFFF",
  white_rgb: "255, 255, 255",
  red: "#b81a1a",
  green: "#32fd43",
  grey: "#A5A093",
  light_grey: "#F2EFEB",
  light_grey_rgb: "242, 239, 235",
  dark_grey: "#191A1C",
  dark_grey_rgb: "25, 26, 28",
  black: "#000000",
  black_rgb: "0, 0, 0"
};

export default palette;
