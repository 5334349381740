import { Stack, Backdrop, Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import Layout from "../components/Layout";
import Settings from "../components/Settings";

export default function SpotOverlay({handleClose, spot}) {

  const ref = useRef(null);
  const [hideSettings, setHideSettings] = useState(false);
  const sm = useMediaQuery("(max-width:600px)");

  const handleScroll = (e) => {
    if(e.target.scrollTop > 10) setHideSettings(true);
    else setHideSettings(false);
  };

  useEffect(() => {
    const el = ref.current;
    el.addEventListener("scroll", handleScroll);
    return () => el && el.removeEventListener("scroll",handleScroll);
  }, []);

  return (
    <Backdrop style={{ position: "absolute", zIndex: 9 }} open={true}>
      <Layout innerRef={ref} full justify="center">
        <Box p={sm ? "30px" : 10} style={{paddingTop: "7rem"}}>
          <Stack direction="column" alignItems="flex-start" spacing={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" style={{width: "100%"}}>
              <Typography variant="pretitle">
                {spot.title}
              </Typography>
              <Button variant="text-small" onClick={handleClose}>
                <MdClose size={30}/>
              </Button>
            </Stack>
            <Typography variant="text">
              {spot.desc}
            </Typography>
            {spot.image && <Box maxWidth="400px">
              <img src={spot.image} alt="spot" style={{maxWidth: "100%", maxHeight: "100%"}}/>
            </Box>}
          </Stack>
        </Box>
      </Layout>
      <Settings hide={hideSettings}/>
    </Backdrop>
  );
}