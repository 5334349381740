import { Box, Button, Stack, Typography } from "@mui/material";
import { MdClose } from "react-icons/md";
import palette from "../config/themes/palette";
import { useTheme } from "../contexts/theme";
const width = 450;
const height = 500;

export default function MarkerPopup({spot, handleClose}) {

  const { theme } = useTheme();

  const left = spot && `${spot.screen.x+width > window.innerWidth - 20 ? Math.max(20, spot.screen.x-width) : spot.screen.x}px`;
  const top = spot && `${spot.screen.y+height > window.innerHeight - 20 ? Math.max(20, spot.screen.y-height) : spot.screen.y}px`;

  return (
    <Box p={0}
    style={{
      position: "absolute",
      top, left, width, height,
    }}>
      <Stack spacing={2} p={3} 
      style={{
        backgroundColor: theme === "day" ? `${palette.white}CC` : `${palette.dark_grey}F2`,
        overflowY: "auto", maxHeight: "100%"
      }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="pretitle">
            {spot.title}
          </Typography>
          <Button variant="text-small" onClick={handleClose}>
            <MdClose size={30}/>
          </Button>
        </Stack>
        <Typography variant="text">
          {spot.desc}
        </Typography>
        {spot.image && <Box maxWidth={width} maxHeight={height}>
          <img src={spot.image} alt="spot" style={{maxWidth: "100%", maxHeight: "100%"}}/>
        </Box>}
      </Stack>
    </Box>
  );
}