import { Howl, Howler } from 'howler';

Howler.usingWebAudio = false;
Howler.autoUnlock = true;

export default class RandomizedPlayer {
  constructor({
    id,
    soundUrls,
    soundDelays,
    loop = true,
    volume = 1,
    fadeOutDuration,
    onLoad
  }) {

    this.id = id;

    this._soundURLs = soundUrls || [];

    this.SOUNDS_NUMBER = this._soundURLs.length;

    this.CURRENT_SOUND_IDX = 0;

    this._soundDelays = soundDelays || [100];

    this._loop = loop;

    this._VOLUME = volume;

    this._FADE_OUT_DURATION = fadeOutDuration;

    Howler.volume(1);

    if (onLoad) {
      this.onLoad = onLoad;
      this.loadWelcome();
    }
  }

  clear() {
    console.log(`*** Player ${this.id}, Clear random player ***`);

    // stop and unload currently playing sounds
    if (this._nextQueuedSound) {
      this._nextQueuedSound.off('load');
      this._nextQueuedSound.off('end');
      this._nextQueuedSound.off('fade');
      this._nextQueuedSound.unload();
    }

    // clear scheduled plays
    clearTimeout(this._startTimeout);
    clearTimeout(this._nextQueuedSoundTimeout);

    this.isFadingOut = false;
  }

  start() {

    // clears any already queued sound
    this.clear();

    // starts the player
    this.startRandomPlayer();
  }

  startRandomPlayer() {

    // shuffle sounds array
    this._shuffledSoundURLs = this.shuffle(this._soundURLs);
    //console.log(`*** Player ${this.id}, StartRandomPlayer shuffled sounds ***`, this._shuffledSoundURLs);

    this.playAndScheduleNext();
  }

  playAndScheduleNext() {

    if (this.isFadingOut) {
      return;
    }

    // pick URL
    const url = this._shuffledSoundURLs[this.CURRENT_SOUND_IDX];

    // pick delay
    const delay = this.shuffle(this._soundDelays).map(el => el * 1000).pop();

    console.log(`*** Player ${this.id}, Next scheduled sound is ${url} in ${delay} ms ***`);

    // create sound instance
    const sound = new Howl({
      src: [url],
      // format: 'ogg',
      html5: true,
      autoplay: false,
      volume: this._VOLUME || 1
    });

    // recursively schedule next sound when finished
    sound.on('end', () => {

      const lastSound = this.CURRENT_SOUND_IDX === this.SOUNDS_NUMBER - 1;

      if (lastSound && this._loop) {
        // if last sund and loop is active, start over
        this.CURRENT_SOUND_IDX = 0;

        this.reshuffleSounds();

        this.playAndScheduleNext();
        return;
      } else if (!lastSound) {
        // if is not last sound, go to next
        this.CURRENT_SOUND_IDX += 1;
        this.playAndScheduleNext();
        return;
      }

      // if last sound and loop is off, do nothing
    });

    sound.once('load', () => {
      this._nextQueuedSound = sound;
      this._nextQueuedSoundTimeout = setTimeout(() => {
        console.log(`*** Player ${this.id}, Playing Sound ***`, url);
        sound.play();
      }, delay);
    });
  }

  reshuffleSounds() {
    const currentShuffledSounds = [...this._shuffledSoundURLs];
    const newShuffledSounds = this.shuffle(this._soundURLs);

    // avoid sound repetition
    if (newShuffledSounds[0] === currentShuffledSounds[newShuffledSounds.length - 1]) {
      // shuffle sounds again
      this.reshuffleSounds();
    } else {
      this._shuffledSoundURLs = newShuffledSounds;
    }
  }

  fadeOut() {
    if (this.isFadingOut) return;

    clearTimeout(this._nextQueuedSoundTimeout);
    if (this._nextQueuedSound.playing()) {
      console.log(`*** Player ${this.id}, Fadeout sound ${this._FADE_OUT_DURATION}ms ***`);
      // fadeout
      this.isFadingOut = true;
      this._nextQueuedSound.on('fade', () => this._nextQueuedSound.volume() === 0 && this.isFadingOut && this.clear());
      this._nextQueuedSound.fade(this._VOLUME, 0, this._FADE_OUT_DURATION);
    } else {
      this.clear();
    }
  }

  loadWelcome(autoplay = false) {
    const welcome = new Howl({
      src: './sound/en_ecoute_phase-logo.mp3',
      //format: 'mp3',
      html5: true,
      autoplay
    });
    welcome.once('load', () => this.onLoad && this.onLoad());
    this.welcome = welcome;
  }

  playWelcome() {
    if (this.welcome && this.welcome.state("loaded"))
      !this.welcome.playing() && this.welcome.play();
    else this.loadWelcome(true);
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }

  shuffle(array) {
    const arr = [...array];
    arr.sort(() => Math.random() - 0.5);
    return arr;
  }

  setVolume(value) {
    Howler.volume(value);
  }
}