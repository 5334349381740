import React from "react";
import { useTheme } from "../contexts/theme";
import {
  Stack,
  Typography
} from "@mui/material";
import String from "./String";
import MarkerIcon from "./MarkerIcon";

export default function MapIcons() {
  const { theme } = useTheme();
  return (
    <Stack style={{position: "absolute", bottom: "30px", left: "30px"}}
    direction="column" spacing={3} alignItems="flex-start">
      <Stack direction="row" spacing={3} alignItems="center">
        <MarkerIcon theme={theme} type="artwork" active={false}/>
        <Typography variant="pretitle"><String s="type_artwork"/></Typography>
      </Stack>
      <Stack direction="row" spacing={3} alignItems="center">
        <MarkerIcon theme={theme} active={false}/>
        <Typography variant="pretitle"><String s="type_others"/></Typography>
      </Stack>
    </Stack>
  );
}
