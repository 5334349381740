const fr = {
  lang: "Français",
  select_lang: "Choisissez votre langue",
  day: "Jour",
  night: "Nuit",
  overlay_welcome: "BIENVENUE À",
  overlay_title: "EN ÉCOUTE",
  overlay_subtitle: "UNE ŒUVRE DIGITAL AUDIOVISUELLE",
  overlay_start: "ENTRER",
  type_artwork: "Œuvre",
  type_others: "Point d'intérêt",
  video_unsupported: "Votre browser ne peut pas reproduire cette vidéo",
  close: "Fermer",
  about_title: "À propos du projet",
  about_text: [
    "http://www.amandine-guruceaga.com/",
    "La sculpture Belladonna-Phénix a été créée par l’artiste Amandine Guruceaga en 2021 pour le centre d’art Vent des Forêts. S’élevant entre un merisier et un hêtre en lisière de forêt, ce bas-relief de 6,50 m de haut, ciselé et passé à la flamme du chalumeau suggère une renaissance par le feu et miroite au soleil. Les flammes de métal, souples, sont sensibles aux mouvements du vent et offrent aux plus curieux un dialogue sonore avec la forêt.",
    "Mezzo Forte a créé la voix de la sculpture à partir des enregistrements des phases de travail des métaux. Les participants plongent dans une expérience audio-visuelle avec un smartphone et un casque à conduction osseuse, en s'approchant tout simplement de la sculpture.",
    "https://mezzoforte.design/",
    "Mezzo Forte est un creative tech studio né en 2019 de l'idée d'une équipe de sound designers, ingénieurs du son, développeurs et chercheurs, dans le but de mettre une expérience pluriannuelle dans le domaine de l'audio 3D au service de la culture, de l'art et de la narration. Mezzo Forte crée des expériences immersives en réalité acoustique augmentée, à destination de musées, spectacles, concerts et visites guidées, toujours dans un souci d'innovation, pour permettre à tout projet d'acquérir une nouvelle dimension grâce au son.",
    "https://ventdesforets.com/",
    "Vent des Forêts, centre d’art contemporain d’intérêt national, affirme depuis 24 ans la rencontre fructueuse du monde rural et de l’art contemporain. Situé au cœur du département de la Meuse, en région Grand Est, Vent des Forêts est porté depuis 1997 par une association qui fédère 6 villages agricoles et forestiers : Fresnes-au-Mont, Lahaymeix, Nicey-sur-Aire, Pierrefitte-sur-Aire, Dompcevrin et Ville-devant-Belrain.",
    "Mezzo Forte est soutenu pour ce projet par le Ministère de la Culture Français, en tant que lauréat du prix Services Numériques Innovants"
  ],
  info_title: "Où le trouver",
  info_text: [
    "Nous sommes au cœur du département de la Meuse, en région Grand Est. Vent des Forêts est un centre d’art contemporain d’intérêt national, où les artistes d’aujourd’hui sont invités à développer un travail de création en prise directe avec le territoire. Plasticiens, sculpteurs, designers, nationaux et internationaux s’emparent de l’environnement forestier pour créer des œuvres in situ. Exposées en pleine nature, parfois discrètes, souvent monumentales, elles se modifient avec le temps et au fil des saisons.",
    "Avec des temps de résidence, qui vont de plusieurs semaines à quelques mois, les artistes inscrivent leur production dans un environnement naturel dans le respect des usages partagés de la forêt, dont les 5 000 hectares de parcelles communales et domaniales sont en constante évolution.",
    "Fruits de ces résidences, plus de 230 œuvres ont vu le jour, dont près de 130 sont actuellement visibles le long de 45 kilomètres de sentiers forestiers spécifiquement balisés et librement accessibles, composés de 7 boucles de 1 à 4 heures de marche.",
    "La sculpture Belladonna-Phénix, créée en 2021 par l’artiste Amandine Guruceaga, est située au point rouge sur la carte. Mezzo Forte a créé une œuvre sonore intitulée En Ecoute, intimement associée à la sculpture, interprétée avec une performance live sur place en juillet 2021. Avec ce portail, nous proposons cette expérience en version numérique.",
  ],
  use_headphones: "Use your headphones or good speakers to dive into this soundwork"
};

export default fr;
