import React, { useEffect } from "react";
import { MapContainer, useMap, useMapEvents } from "react-leaflet";
import Marker from 'react-leaflet-enhanced-marker'
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import { STADIA_MAPS_API_KEY, PRODUCTION_URL } from "../config/map/index";

import { useTheme } from "../contexts/theme";
import { useExperience } from "../contexts/experience";
import MarkerIcon from "./MarkerIcon";

export default function Map() {

  const { theme } = useTheme();
  const { spots, selected, setSelected } = useExperience();

  const removeAttribution = (map) => map.attributionControl._container.style = 'display: none';

  const calcMapBounds = (padding) => {
    let minLat;
    let minLon;
    let maxLat;
    let maxLon;
    spots.forEach((s) => {
      if(!minLat || s.lat < minLat) minLat = s.lat;
      if(!minLon || s.lon < minLon) minLon = s.lon;
      if(!maxLat || s.lat > maxLat) maxLat = s.lat;
      if(!maxLon || s.lon > maxLon) maxLon = s.lon;
    })
    return [[minLat-padding[0],minLon-padding[1]],[maxLat+padding[0],maxLon+padding[1]]];
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <MapContainer
        style={{ height: "100%", zIndex: 0, background: "black" }}
        bounds={calcMapBounds([0.001,0.002])}
        scrollWheelZoom={false}
        zoomControl={false}
        dragging={false}
        doubleClickZoom={false}
        whenCreated={removeAttribution}
      >
        <MapEvents onClick={() => setSelected(undefined)}/>
        {theme === "night" ? (
          <>
            <Tile theme="day" />
            <Tile theme="night" />
          </>
        ) : (
          <>
            <Tile theme="night" />
            <Tile theme="day" />
          </>
        )}

        {spots &&
          spots.map((spot) => (
            <Marker
              key={spot.id}
              icon={<MarkerIcon type={spot.type} active={selected && selected.id === spot.id} theme={theme} />}
              position={[spot.lat, spot.lon]}
              autoPanOnFocus={false}
              eventHandlers={{
                click: (e) => {
                  const select = spot;
                  select.screen = {
                    x: e.originalEvent.clientX,
                    y: e.originalEvent.clientY
                  };
                  console.log('Spot selected', spot);
                  setSelected(select);
                }
              }}
            />
          ))}
      </MapContainer>
    </div>
  );
}

function Tile({ theme }) {
  const map = useMap();
  useEffect(() => {
    const tiles = {
      day: {
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: `https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${STADIA_MAPS_API_KEY}`,
      },
      night: {
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: `https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=${STADIA_MAPS_API_KEY}`,
      },
    };
    const tile = tiles[theme];
    const tileLayer = L.tileLayer(tile.url, {
        attribution: tile.attribution,
        subdomains: PRODUCTION_URL,
        minZoom: 5,
      })
      .addTo(map);
    return () => tileLayer.removeFrom(map);
  }, [theme, map]);
  return null;
}

function MapEvents({onClick}) {
  useMapEvents({
    click() { 
      console.log("Map clicked!");
      onClick();
    }
  })
  return <></>
}
