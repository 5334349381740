import React, { useCallback, useEffect, useMemo, useState } from "react";
import { 
  spotsCfg,
  PLAYER_1_SOUNDS,
  PLAYER_2_SOUNDS,
  PLAYER_3_SOUNDS,
  PLAYER_1_DELAYS,
  PLAYER_2_DELAYS,
  PLAYER_3_DELAYS,
  FADE_OUT_DURATION,
  REAPER_OSC_PORT,
  REAPER_START_TRIGGER,
  REAPER_STOP_TRIGGER
} from "../config/experience"
import { useLanguage } from "./language";
import RandomizedPlayer from "../utils/RandomizedPlayer";
import OSC, {STATUS} from "osc-js"

const ExperienceContext = React.createContext(null);

export default function Experience({ children }) {

  const { language } = useLanguage();
  const [spots, setSpots] = useState(spotsCfg);
  const [selected, setSelected] = useState();
  const [lastSelected, setLastSelected] = useState();
  const [loading, setLoading] = useState(true);
  const [globalVolume, setGlobalVolume] = useState(1);

  const randomPlayer1 = useMemo(() => {
    console.log("1 init");
    return new RandomizedPlayer({
    id: 1,
    soundUrls: PLAYER_1_SOUNDS,
    soundDelays: PLAYER_1_DELAYS,
    volume: .75,
    fadeOutDuration: FADE_OUT_DURATION,
    onLoad: () => setLoading(false)
  })},[]);
  
  const randomPlayer2 = useMemo(() => {
    console.log("2 init");
    return new RandomizedPlayer({
    id: 2,
    soundUrls: PLAYER_2_SOUNDS,
    soundDelays: PLAYER_2_DELAYS,
    volume: .35,
    fadeOutDuration: FADE_OUT_DURATION
  })},[]);
  
  const randomPlayer3 = useMemo(() => {
    console.log("3 init");
    return new RandomizedPlayer({
    id: 3,
    soundUrls: PLAYER_3_SOUNDS,
    soundDelays: PLAYER_3_DELAYS,
    fadeOutDuration: FADE_OUT_DURATION
  })},[]);

  const osc = useMemo(() => {
    const hostname = window?.location?.hostname
    if(hostname !== "localhost") return undefined;
    const oscConnection = new OSC();
    oscConnection.open({ port: REAPER_OSC_PORT });
    console.log(`Opened OSC connection on port ${REAPER_OSC_PORT}`);
    return oscConnection;
  },[])

  const stopRandomPlayers = useCallback(() => {
    randomPlayer1.fadeOut();
    randomPlayer2.fadeOut();
    randomPlayer3.fadeOut();
    console.log("Random players stopped");
  },[randomPlayer1, randomPlayer2, randomPlayer3]);

  const startRandomPlayers = useCallback(() => {
    randomPlayer1.start();
    randomPlayer2.start();
    randomPlayer3.start();
    console.log("Random players started");
  },[randomPlayer1, randomPlayer2, randomPlayer3]);

  const start = useCallback(() => {
    randomPlayer1.playWelcome();
    startRandomPlayers();
  }, [startRandomPlayers, randomPlayer1]);

  const sendTrigger = useCallback((type) => {
    if (!osc || osc.status() !== STATUS.IS_OPEN) return;
    osc.send(new OSC.Message(type))
    console.log(`OSC trigger sent ${type}`);
  },[osc])

  useEffect(() => {
    const translated = spotsCfg.map(spot => {
      spot.title = spot[language] ? spot[language].title : spot.en.title;
      spot.desc = spot[language] ? spot[language].desc : spot.en.desc;
      return spot;
    });
    setSpots(translated);
  }, [language])

  useEffect(() => {
    randomPlayer1 && randomPlayer1.setVolume(globalVolume);
    randomPlayer2 && randomPlayer2.setVolume(globalVolume);
    randomPlayer3 && randomPlayer3.setVolume(globalVolume);
  }, [globalVolume, randomPlayer1, randomPlayer2, randomPlayer3])

  useEffect(() => {
    if (selected && selected.type && selected.type === "artwork") {
      stopRandomPlayers();
      sendTrigger(REAPER_START_TRIGGER);
    } else {
      if (lastSelected && lastSelected.type && lastSelected.type === "artwork") {
        startRandomPlayers();
        sendTrigger(REAPER_STOP_TRIGGER);
      }
    }
    setLastSelected(selected);
  }, [selected])

  return (
    <ExperienceContext.Provider
      value={{ spots, start, selected, setSelected, loading, setGlobalVolume, globalVolume }}
    >
      {children}
    </ExperienceContext.Provider>
  );
}

export const useExperience = () => React.useContext(ExperienceContext);