import palette from "../config/themes/palette";
import "./MarkerIcon.css";

const colors = {
  day: {
    artwork: palette.red,
    others: palette.black
  },
  night: {
    artwork: palette.red,
    others: palette.white
  }
}

export default function MarkerIcon({type=undefined, theme, active=false, size=25}) {

  const innerColor = colors[theme][type] || colors[theme].others; 
  const outerColor = colors[theme][type] || colors[theme].others;

  return (
    <svg
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      id="markerIcon"
      width={size}
      height={size}
      className={active ? "bouncing" : ""}
    >
      <circle
        strokeWidth={0} fill={innerColor}
        id="inner"
        cx={size/2}
        cy={size/2}
        r={size/2.8} />
      <circle
        strokeWidth={size/20} stroke={outerColor} fill="none"
        id="outer"
        cx={size/2}
        cy={size/2}
        r={size/2.2} />
    </svg>    
  );
}