import {
  Box,
  Button,
  Typography,
  Backdrop,
  Stack,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import String from "../components/String";
import Layout from "../components/Layout";
import Settings from "../components/Settings";
import { GiSoundWaves } from "react-icons/gi"

export default function WelcomeOverlay({ show, start, loading }) {
  const short = useMediaQuery("(max-height:700px)");
  const sm = useMediaQuery("(max-width:600px)");
  return (
    <Backdrop style={{ position: "absolute", zIndex: 9 }} open={show}>
      <Layout full justify="center">
        <Box textAlign="center" ml={4} mr={4}>
          {
            show &&
            <Stack pt={10} pb={short && !sm ? 10 : 0} spacing={short && !sm ? 3 : 12}>
              <Stack spacing={1}>
                <Typography variant="pretitle" pb={short ? 2 : 4}>
                  <String s="overlay_welcome" />
                </Typography>
                <Typography variant="title" pb={short ? 2 : 4}>
                  <String s="overlay_title" />
                </Typography>
                <Typography variant="pretitle" mb={3}>
                  <String s="overlay_subtitle" />
                </Typography>
              </Stack>
              <Button
                variant="text-large"
                disabled={loading}
                onClick={start}
              >
                {loading ? <CircularProgress/> : <String s="overlay_start" />}
              </Button>
              <Stack direction={sm ? "column" : "row"} alignItems="center" style={{maxWidth: "70%", marginLeft: "auto", marginRight: "auto"}}>
                <Box width={40} height={40}>
                  <GiSoundWaves size={40}/>
                </Box>
                <Typography variant="text" ml={sm ? 0 : 2}>
                  <String s="use_headphones"/>
                </Typography>
              </Stack>
            </Stack>
          }
        </Box>
        <Settings/>
      </Layout>
    </Backdrop>
  );
}
