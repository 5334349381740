const styles = {
  typography: {
    title: {
      fontFamily: "IBM Plex Sans Condensed",
      fontWeight: "700",
      fontSize: "5rem",
      lineHeight: "5rem"
    },
    pretitle: {
      fontFamily: "IBM Plex Mono",
      fontWeight: "200",
      fontSize: "1.2rem",
      lineHeight: "1.2rem"
    },
    text: {
      fontFamily: "IBM Plex Mono",
      fontSize: ".8rem",
      lineHeight: ".8rem"
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {fontFamily: "IBM Plex Mono"}
      },
      defaultProps: {
        variantMapping: {
          title: "h2",
          pretitle: "h5",
          subtitle: "h4",
          text: "p",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: { textAlign: "left" },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: { fontSize: "1rem" },
        input: { width: "100px" },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "IBM Plex Mono",
          textTransform: "none",
          padding: "0px",
          margin: "0px",
          minWidth: "unset"
        },
      },
      variants: [
        {
          props: { variant: "text-large" },
          style: {
            fontFamily: "IBM Plex Mono",
            fontWeight: "bold",
            fontSize: "3rem",
            lineHeight: "4rem"
          },
        },
        {
          props: { variant: "text-small" },
          style: {
            fontFamily: "IBM Plex Mono",
            fontWeight: "regular",
            fontSize: "1.5rem",
            lineHeight: "2rem"
          },
        },
      ],
    },
  },
  shadows: (function () {
    return Array.from(Array(25).keys()).map(() => "none");
  })(),
};

const common = { styles };

export default common;
