import React, { useState } from "react";
import languages, { default_language } from "../config/languages";

const LanguageContext = React.createContext(null);

export default function Language({ children }) {
  const [language, setLanguage] = useState(default_language);
  const availableLanguages = Object.keys(languages);
  const content = (key, l) => languages[l || language][key];
  // const welcomeMessage = WELCOME_MESSAGES[language];

  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, availableLanguages, content }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguage = () => React.useContext(LanguageContext);
