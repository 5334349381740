  export const spotsCfg = [
    {
      id: "main",
      lat: 48.9189514,
      lon: 5.4191603,
      type: "artwork",
      video: "./video/VdF_artwork_animation.mp4",
      en: {
        title: "Artwork",
        desc: "This is the artwork"
      },
      fr: {
        title: "Artwork",
        desc: "This is the artwork"
      }
    },
    {
      id: "sp1",
      lat: 48.8909732,
      lon: 5.4682491,
      image: "./image/spot1.jpg",
      en: {
        title: "Rte de Bar le Duc",
        desc: "Amandine produced her work in the workshop of boilermaker Dominique Rennesson (Chauvoncourt, 55). Mezzo Forte recorded the sounds of the metals on site during the production phase. These sounds and their electronic elaborations are the only material used to create the voice of the sculpture."
      },
      fr: {
        desc: "Rte de Bar le Duc",
        title: "Amandine a réalisé son œuvre dans l’atelier du chaudronnier Dominique Rennesson (Chauvoncourt, 55). Mezzo Forte a enregistré sur place les sons des métaux pendant la phase de réalisation. Ces sons et leurs élaborations électroniques sont la seule matière utilisée pour créer la voix de la sculpture."
      }
    },
    {
      id: "sp2",
      lat: 48.909207,
      lon: 5.422315,
      image: "./image/spot2.jpg",
      en: {
        title: "Installation",
        desc: "During the inauguration of the work, the public is equipped with headphones leaving their ears free, allowing them to listen to the soundwork by Mezzo Forte without cutting themselves off from the environment and the people around."
      },
      fr: {
        title: "Installation",
        desc: "Lors de l’inauguration de l’œuvre, le public a été équipé de casques qui permettaient d’écouter la création sonore Mezzo Forte avec les oreilles libres, sans se couper de l’environnement et des personnes autour."
      }
    },
    {
      id: "sp3",
      lat: 48.913493,
      lon: 5.417218,
      image: "./image/spot3.jpg",
      en: {
        title: "First part",
        desc: "Thanks to the mobile application developed by Mezzo Forte, the public listen to a first generative sound layer while walking towards the place where the sculpture is installed. The sounds of metals and those of nature merge in an unprecedented augmented reality experience."
      },
      fr: {
        title: "Première partie",
        desc: "Grâce à l’application mobile développée par Mezzo Forte, le public a pu écouter un premier tableau sonore génératif tout en marchant vers le lieu où la sculpture est installée. Les sons des métaux et ceux de la nature se fusionnent dans une expérience de réalité augmentée inédite."
      }
    },
    {
      id: "sp4",
      lat: 48.8983955,
      lon: 5.4256027,
      image: "./image/spot4.jpg",
      en: {
        title: "Vent des Forêts",
        desc: "Mezzo Forte and Vent des Forêts met around a form of visit that challenges perception differently, with multiple modalities and an ecological approach to augmented reality. The team investigated this topic also with an artistic research residency at Ircam in Paris."
      },
      fr: {
        title: "Vent des Forêts",
        desc: "La rencontre entre Mezzo Forte et Vent des Forêts a eu lieu autour d’une forme de visite qui interpelle la perception autrement, avec des modalités multiples et une approche écologique à la réalité augmentée. Cette réflexion a fait aussi l’objet d’une résidence en recherche artistique de l’équipe à l’Ircam de Paris."
      }
    },
    {
      id: "sp5",
      lat: 48.916844,
      lon: 5.416151,
      image: "./image/spot5.jpg",
      en: {
        title: "Second part",
        desc: "By getting close to the sculpture, the public dives in an immersive sound experience projected both by speakers hidden in the forest and by headphones: a listening experience that is both intimate and shared, revealing the voice of the sculpture."
      },
      fr: {
        title: "Deuxième partie",
        desc: "En s’approchant de la sculpture, le public a été plongé dans une expérience sonore immersive projetée à la fois par des enceintes dissimulées dans la forêt et par les casques : une écoute à la fois intime et partagée qui révèle la voix de la sculpture."
      }
    }
  ]

  export const PLAYER_1_SOUNDS = [
    './sound/BCH_1_mp3/BCH_1_1.mp3',
    './sound/BCH_1_mp3/BCH_1_2.mp3',
    './sound/BCH_1_mp3/BCH_1_3.mp3',
    './sound/BCH_1_mp3/BCH_1_4.mp3',
    './sound/BCH_1_mp3/BCH_1_5.mp3'
  ];
  export const PLAYER_2_SOUNDS = [
    './sound/BCH_2_mp3/BCH_2_1.mp3',
    './sound/BCH_2_mp3/BCH_2_2.mp3',
    './sound/BCH_2_mp3/BCH_2_3.mp3',
    './sound/BCH_2_mp3/BCH_2_4.mp3',
    './sound/BCH_2_mp3/BCH_2_5.mp3'
  ];
  export const PLAYER_3_SOUNDS = [
    './sound/BCH_3_mp3/BCH_3_1.mp3',
    './sound/BCH_3_mp3/BCH_3_2.mp3',
    './sound/BCH_3_mp3/BCH_3_3.mp3',
    './sound/BCH_3_mp3/BCH_3_4.mp3',
    './sound/BCH_3_mp3/BCH_3_5.mp3',
    './sound/BCH_3_mp3/BCH_3_6.mp3'
  ];

  export const PLAYER_1_DELAYS = [20, 25, 30, 35, 40, 45]; // seconds
  export const PLAYER_2_DELAYS = [10, 15, 20, 25, 30, 35]; // seconds
  export const PLAYER_3_DELAYS = [5, 10, 15, 20, 25]; // seconds

  export const FADE_OUT_DURATION = 5000; // ms

  export const REAPER_OSC_PORT = 8081;
  export const REAPER_START_TRIGGER = "/play";
  export const REAPER_STOP_TRIGGER = "/stop";