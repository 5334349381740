import React, { useCallback, useState } from "react";
import Layout from "../components/Layout";
import Settings from "../components/Settings";
import Map from "../components/Map";
import { useExperience } from "../contexts/experience";
import MapIcons from "../components/MapIcons";
import WelcomeOverlay from "./WelcomeOverlay";
import VideoOverlay from "./VideoOverlay";
import MarkerPopup from "../components/MarkerPopup";
import AboutOverlay from "./AboutOverlay";
import InfoOverlay from "./InfoOverlay";
import { useMediaQuery } from "@mui/material";
import SpotOverlay from "./SpotOverlay";

export default function Main() {

  const { start, selected, setSelected, loading, globalVolume } = useExperience();
  const [showOverlay, setShowOverlay] = useState(true);
  const sm = useMediaQuery("(max-width:600px)");
  
  const handleStart = useCallback(() => {
    setShowOverlay(false);
    start();
  }, [start])

  return (
    <Layout full>
      <WelcomeOverlay
        start={handleStart}
        show={showOverlay}
        loading={loading}
      />
      <Map></Map>
      {!showOverlay && <>
        <Settings/>
        <AboutOverlay handleOpen={() => setSelected(undefined)}/>
        <InfoOverlay handleOpen={() => setSelected(undefined)}/>
        <MapIcons/>
      </>}
      { !showOverlay && selected && <>
        {
          selected.type === "artwork" ? (
            <VideoOverlay spot={selected} handleClose={() => setSelected(undefined)} globalVolume={globalVolume}/>
          ) : (
            sm ? <SpotOverlay spot={selected} handleClose={() => setSelected(undefined)}/>
            : <MarkerPopup spot={selected} handleClose={() => setSelected(undefined)}/>
          )
        }
      </>}
    </Layout>
  );
}