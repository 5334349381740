import { Stack, Backdrop, Box, Button, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import Layout from "../components/Layout";
import Settings from "../components/Settings";
import String from "../components/String";

export default function VideoOverlay({spot, handleClose, globalVolume}) {

  const sm = useMediaQuery("(max-width:600px)");
  const video = useRef();
  const box = useRef();
  const fading = useRef(1);
  const [disabled, setDisabled] = useState(false);

  const fadeThenClose = useCallback(() => {
    const startVolume = video.current.volume;
    const interval = setInterval(() => {
      if (fading.current  >= 0.0001) {
        video.current.volume = startVolume * fading.current ;
        box.current.style.opacity = fading.current ;
        fading.current -= fading.current * 0.02;
      }
      else {
        clearInterval(interval);
        setDisabled(false);
        handleClose();
      }
    },5)
  }, [video, box, handleClose])

  useEffect(() => {
    video.current.volume = globalVolume;
  }, [globalVolume, video])

  return (
    <Backdrop ref={box} style={{ overflow: "hidden", position: "absolute", zIndex: 9 }} open={true}>
      <Layout full noOverflow justify="center">
        <Box p={sm ? "30px" : 10} textAlign="center">
          <Stack direction="column" alignItems="center" spacing={2}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" width="100%">
              <Button variant="text-small"
              disabled={disabled}
              onClick={() => {
                setDisabled(true);
                fadeThenClose();
              }}>
                <MdClose size={30}/>
              </Button>
            </Stack>
            {spot.video &&
              <video ref={video} width="100%" height="auto" autoPlay onEnded={handleClose} style={{maxHeight: "70vh"}}>
                <source src={spot.video} type="video/mp4"/>
                <source src={spot.video} type="video/ogg"/>
                <String s="video_unsupported"/>
              </video>
            }
          </Stack>
        </Box>
      </Layout>
      <Settings/>
    </Backdrop>
  );
}