const en = {
  lang: "English",
  select_lang: "Choose your language",
  day: "Day",
  night: "Night",
  overlay_welcome: "WELCOME TO",
  overlay_title: "EN ÉCOUTE",
  overlay_subtitle: "AN AUDIO-VISUAL DIGITAL ARTWORK",
  overlay_start: "ENTER",
  type_artwork: "Artwork",
  type_others: "Point of interest",
  video_unsupported: "Your browser cannot reproduce this video",
  close: "Close",
  about_title: "About the project",
  about_text: [
    "http://www.amandine-guruceaga.com/",
    "The Belladonna-Phoenix sculpture was created by the artist Amandine Guruceaga in 2021 for the Vent des Forêts art center. Rising between a cherry tree and a beech tree on the edge of the forest, this 6.50 m high bas-relief, chiseled and torched, suggests a rebirth by fire and shimmers in the sun. The flexible metal flames are sensitive to the movements of the wind and offer the most curious a sound dialogue with the forest.",
    "Mezzo Forte created the voice of the sculpture from the recordings of the metalworking phases. Participants immerse themselves in an audio-visual experience with a smartphone and bone conduction headphones, by simply approaching the sculpture.",
    "https://mezzoforte.design/",
    "Mezzo Forte is a creative-tech studio created in 2019 by a team of sound designers, engineers, developers and researchers, with the aim of serving culture, art and storytelling thanks to their long term experience in the field of 3D audio. ",
    "Mezzo Forte creates immersive experiences in augmented acoustic reality, intended for museums, shows, concerts and guided tours, always looking for innovation, to give a new dimension to any project thanks to sound.",
    "https://ventdesforets.com/",
    "Vent des Forêts, a contemporary art center of national interest, has been affirming for 24 years the fruitful encounter between the rural world and contemporary art. Located in the heart of the Meuse department, in the Grand Est region, Vent des Forêts has been supported since 1997 by an association which brings together 6 agricultural and forestry villages: Fresnes-au-Mont, Lahaymeix, Nicey-sur-Aire, Pierrefitte-sur- Aire, Dompcevrin and Ville-devant-Belrain.",
    "Mezzo Forte is supported for this project by the French Ministry of Culture, as the winner of the Innovative Digital Services prize"
  ],
  info_title: "Where to find it",
  info_text: [
    "We are in the heart of the Meuse department, in the Grand Est region. Vent des Forêts is a contemporary art center of national interest, where today's artists are invited to develop creative work in direct contact with the territory. National and international visual artists, sculptors, designers, seize the forest environment to create works in situ. Exposed in the middle of nature, sometimes discreet, often monumental, they change over time and with the seasons.",
    "With residence times, which range from several weeks to a few months, the artists place their production in a natural environment while respecting the shared uses of the forest, whose 5,000 hectares of municipal and state plots are constantly changing.",
    "As a result of these residences, more than 230 works have emerged, of which nearly 130 are currently visible along 45 kilometers of specifically marked and freely accessible forest paths, made up of 7 loops of 1 to 4 hours of walking.",
    "The Belladonna-Phénix sculpture, created in 2021 by artist Amandine Guruceaga, is located at the red dot on the map. Mezzo Forte has created a sound work entitled En Ecoute, intimately associated with the sculpture, interpreted with a live performance on site in July 2021. With this portal, we offer this experience in a digital version."
  ],
  use_headphones: "Use your headphones or good speakers to dive into this soundwork"
};

export default en;
