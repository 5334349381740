import React, { useEffect } from "react";
import { MapContainer, useMap } from "react-leaflet";
import Marker from 'react-leaflet-enhanced-marker'
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import { STADIA_MAPS_API_KEY, PRODUCTION_URL } from "../config/map/index";

import { useTheme } from "../contexts/theme";
import { useExperience } from "../contexts/experience";
import MarkerIcon from "./MarkerIcon";

export default function MiniMap() {

  const { theme } = useTheme();
  const { spots } = useExperience();
  const main = spots.find(e => e.id === "main");

  const removeAttribution = (map) => map.attributionControl._container.style = 'display: none';

  return (
    <div style={{ height: "400px", width: "100%" }}>
      <MapContainer
        style={{ height: "100%", zIndex: 0, background: "black" }}
        center={[main.lat, main.lon]}
        zoom={6}
        scrollWheelZoom={false}
        zoomControl={false}
        dragging={false}
        doubleClickZoom={false}
        whenCreated={removeAttribution}
      >
        {theme === "night" ? (
          <>
            <Tile theme="day" />
            <Tile theme="night" />
          </>
        ) : (
          <>
            <Tile theme="night" />
            <Tile theme="day" />
          </>
        )}
        {main && <Marker
          key={main.id}
          icon={<MarkerIcon type="artwork" active={false} theme={theme} />}
          position={[main.lat, main.lon]}
        />}
      </MapContainer>
    </div>
  );
}

function Tile({ theme }) {
  const map = useMap();
  useEffect(() => {
    const tiles = {
      day: {
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: `https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${STADIA_MAPS_API_KEY}`,
      },
      night: {
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: `https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=${STADIA_MAPS_API_KEY}`,
      },
    };
    const tile = tiles[theme];
    const tileLayer = L.tileLayer(tile.url, {
        attribution: tile.attribution,
        subdomains: PRODUCTION_URL,
        minZoom: 6,
      })
      .addTo(map);
    return () => tileLayer.removeFrom(map);
  }, [theme, map]);
  return null;
}
