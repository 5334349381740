import { Stack, Backdrop, Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { MdClose, MdOutlineInfo } from "react-icons/md";
import Layout from "../components/Layout";
import Settings from "../components/Settings";
import String from "../components/String";

export default function AboutOverlay({handleOpen}) {

  const ref = useRef(null);
  const [hideSettings, setHideSettings] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    showOverlay && handleOpen();
  }, [showOverlay, handleOpen])
  const sm = useMediaQuery("(max-width:600px)");

  const handleScroll = (e) => {
    if(e.target.scrollTop > 10) setHideSettings(true);
    else setHideSettings(false);
  };

  useEffect(() => {
    const el = ref.current;
    el.addEventListener("scroll", handleScroll);
    return () => el && el.removeEventListener("scroll",handleScroll);
  }, []);

  return (
    <>
      <Stack style={{position: "absolute", top: sm ? "90px" : "30px", right: "30px"}}>
        <Button 
        variant="text-small"
        onClick={() => setShowOverlay(true)}>
          <MdOutlineInfo size={30}/>
        </Button>
      </Stack>
      <Backdrop style={{ position: "absolute", zIndex: 9 }} open={showOverlay}>
        <Layout innerRef={ref} full justify="flex-start">
          <Box p="30px" style={{paddingTop: "7rem"}}>
            <Stack direction="column" alignItems="center" spacing={3}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                <Typography variant="pretitle">
                  <String s="about_title"/>
                </Typography>
                <Button variant="text-small" onClick={() => setShowOverlay(false)}>
                  <MdClose size={30}/>
                </Button>
              </Stack>
              <Typography component="div" variant="text">
                <String s="about_text"/>
              </Typography>
              {/* <img src="./image/sample_image.jpg" alt="making of" style={{height:"400px"}}/> */}
            </Stack>
          </Box>
        </Layout>
        <Settings hide={hideSettings} hideVolume/>
      </Backdrop>
    </>
  );
}