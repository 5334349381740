import palette from "../config/themes/palette";
import { useLanguage } from "../contexts/language";

export default function String({ s, l }) {
  const { content } = useLanguage();
  const res = content(s, l);
  if (Array.isArray(res)) {
    return res.map((e, idx) => {
      if (e.search("http") >= 0)
        return <a key={idx} className="link" style={{paddingBottom: "2px", color: palette.grey}} 
          href={e} target="_blank" rel="noreferrer">{e}</a>
      else
        return <p key={idx} style={{paddingBottom: "5px"}}>{e}</p>
    })
  }
  return content(s, l);
}
