import React from "react";
import { Stack, Container } from "@mui/material";

export default function Layout({
  top,
  bottom,
  full,
  noOverflow,
  direction,
  justify,
  align,
  children,
  innerRef
}) {
  const root = { width: "100%", height: "100%" };
  const content = { flexGrow: 1, overflowY: noOverflow ? "hidden" : "auto", position: "relative" };
  const layout = (
    <Stack style={root}>
      {top}
      <Stack ref={innerRef}
        direction={direction || "column"}
        justifyContent={justify || "space-evenly"}
        alignItems={align || "center"}
        style={content}
      >
        {children}
      </Stack>
      {bottom}
    </Stack>
  );
  return !full ? <Contained>{layout}</Contained> : layout;
}

function Contained({ children }) {
  return (
    <Container maxWidth="false" style={{ height: "100%" }}>
      {children}
    </Container>
  );
}
