import React from "react";
import { useTheme } from "../contexts/theme";
import {
  Stack,
  Typography,
  Button,
  useMediaQuery,
  Slider
} from "@mui/material";
import { useLanguage } from "../contexts/language";
import { MdOutlineLightMode, MdOutlineDarkMode } from "react-icons/md"
import { IoMdVolumeHigh, IoMdVolumeLow, IoMdVolumeMute, IoMdVolumeOff } from "react-icons/io"
import { useExperience } from "../contexts/experience";

export default function Settings({hide, hideVolume}) {
  const { setTheme, theme } = useTheme();
  const { globalVolume, setGlobalVolume } = useExperience();
  const { setLanguage, language } = useLanguage();

  const timeout = React.useRef();

  const [viewSmVolume, setViewSmVolume] = React.useState(false);
  React.useEffect(() => {
    if (viewSmVolume) {
      timeout.current = setTimeout(() => {
        setViewSmVolume(false);
        if (timeout.current) {
          clearTimeout(timeout.current);
          timeout.current = undefined;
        }
      }, 5000)
    } else {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
    }
  }, [viewSmVolume])

  React.useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setViewSmVolume(false);
        if (timeout.current) {
          clearTimeout(timeout.current);
          timeout.current = undefined;
        }
      }, 5000)
    }
  }, [globalVolume])

  const sm = useMediaQuery("(max-width:600px)");
  const style = sm ? {
    position: "absolute",
    top: "30px",
    width: "100%",
    padding: "0 30px",
    display: "flex",
    justifyContent: "space-between",
    opacity: hide ? "0" : "1",
    pointerEvents: hide ? "none" : "auto",
    transition: "opacity .2s"
  } : {
    position: "absolute",
    top: "30px", left: "50%",
    transform: "translateX(-50%)",
    opacity: hide ? "0" : "1",
    pointerEvents: hide ? "none" : "auto",
    transition: "opacity .2s"
  };
  return (
    <>
      <Stack style={style}
      direction="row" spacing={10} alignItems="center">
        <Stack direction="row" spacing={3} alignItems="center">
          <Button 
          style={{opacity: language !== 'en' ? .3 : 1 }}
          variant="text-small"
          onClick={() => setLanguage('en')}>
            En
          </Button>
          <Typography variant="subtitle"> | </Typography>
          <Button 
          style={{opacity: language !== 'fr' ? .3 : 1 }}
          variant="text-small"
          onClick={() => setLanguage('fr')}>
            Fr
          </Button>
        </Stack>
        {!sm && <Stack spacing={2} direction="row" alignItems="center">
          {globalVolume === 0 ? <IoMdVolumeOff style={{width: "80px"}} size={30}/>
          : globalVolume < .3 ? <IoMdVolumeMute style={{width: "80px"}} size={30}/>
          : globalVolume < .7 ? <IoMdVolumeLow style={{width: "80px"}} size={30}/>
          : <IoMdVolumeHigh style={{width: "80px"}} size={30}/>
          }
          <Slider
            size="small"
            color="primary"
            aria-label="Volume" 
            min={0} max={1} 
            value={globalVolume}
            step={0.01}
            onChange={(e) => setGlobalVolume(e.target.value)}
            style={{width: "90px", marginLeft: 0}}
          />
        </Stack>}
        <Stack direction="row" spacing={3} alignItems="center">
          <Button 
          style={{opacity: theme !== 'night' ? .3 : 1 }}
          variant="text-small"
          onClick={() => setTheme('night')}>
            <MdOutlineDarkMode size={30}/>
          </Button>
          <Typography variant="subtitle"> | </Typography>
          <Button 
          style={{opacity: theme !== 'day' ? .3 : 1 }}
          variant="text-small"
          onClick={() => setTheme('day')}>
            <MdOutlineLightMode size={30}/>
          </Button>
        </Stack>
      </Stack>
      {sm && !hideVolume && <Stack spacing={2} direction="column" alignItems="center" 
      style={{
        position: "absolute",
        bottom: "30px",
        right: "30px",
        opacity: hide ? "0" : "1",
        pointerEvents: hide ? "none" : "auto",
        transition: "opacity .2s",
      }}>
        {viewSmVolume && <Slider
          orientation="vertical"
          size="small"
          color="primary"
          aria-label="Volume" 
          min={0} max={1} 
          value={globalVolume}
          step={0.01}
          onChange={(e) => setGlobalVolume(e.target.value)}
          style={{height: "80px", padding: "0 10px 0"}}
        />}
        <Button variant="text" onClick={() => setViewSmVolume(v => !v)}>
          {globalVolume === 0 ? <IoMdVolumeOff size={30}/>
          : globalVolume < .3 ? <IoMdVolumeMute size={30}/>
          : globalVolume < .7 ? <IoMdVolumeLow size={30}/>
          : <IoMdVolumeHigh size={30}/>}
        </Button>
      </Stack>}
    </>
  );
}
