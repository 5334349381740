import React from "react";
import Experience from "./contexts/experience";
import Language from "./contexts/language";
import Theme from "./contexts/theme";
import Main from "./views/Main";

export default function App() {
  return (
    <Theme>
      <Language>
        <Experience>
          <Main/>
        </Experience>
      </Language>
    </Theme>
  );
}